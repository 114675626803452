export const degreesToRadians = (degrees: number) => degrees * (Math.PI / 180);
export const radiansToDegrees = (radians: number) => radians * (180 / Math.PI);

export const sin = (degrees: number) => Math.sin(degreesToRadians(degrees));
export const cos = (degrees: number) => Math.cos(degreesToRadians(degrees));

export const dayOfYear = (date: Date) => {
  const startOfYear = new Date(date.getFullYear(), 0, 0);
  return Math.floor((+date - +startOfYear) / (1000 * 3600 * 24));
};

// it's work for angles from 0 to 360
export const avgAngle = (angles: number[]) => {
  const avgX = angles.reduce((a, b) => a + cos(b), 0) / angles.length;
  const avgY = angles.reduce((a, b) => a + sin(b), 0) / angles.length;
  const res = radiansToDegrees(Math.atan2(avgY, avgX));
  return res < 0 ? res + 360 : res;
};

export function calculateSolarPerformance(
  panelPower: number,
  panelCount: number,
  sunHours: number,
  inverterEfficiency: number,
  tiltAngle: number,
  shading: number
) {
  const tiltCoefficient = 1 - 0.005 * Math.abs(tiltAngle - 30);
  const adjustedPower = panelPower * tiltCoefficient * (1 - shading / 100);
  const expectedPowerOutput =
    adjustedPower * panelCount * sunHours * (inverterEfficiency / 100);

  return {
    expectedPowerOutput,
    optimalTiltAngle: tiltAngle,
  };
}
