import { radiansToDegrees, sin, cos } from "../helpers/mathHelpers";

class SolarAngleService {
  // Tilt angle
  // https://www.topbullshop.com/pages/solar-panel-tilt-angle-calculator
  // https://premc.org/doc/ICREN2020/1_Khari_Sado_Slides.pdf
  public tiltAngle = (latitude: number, delta: number) =>
    Math.abs(latitude - delta);

  // Solar Azimuth Angle (A)
  // https://www.pveducation.org/pvcdrom/properties-of-sunlight/azimuth-angle
  public azimuthAngle(
    lat: number,
    alpha: number,
    delta: number,
    HRA: number,
    LST: number
  ) {
    const angle = radiansToDegrees(
      Math.acos(
        (sin(delta) * cos(lat) - cos(delta) * sin(lat) * cos(HRA)) / cos(alpha)
      )
    );
    return LST < 12 || HRA < 0 ? angle : 360 - angle;
  }

  // Solar Irradiance (I) W/m²
  public solarIrradiance = (I0: number, teta: number) =>
    teta > 90 ? 0 : Math.min(I0 * cos(teta), 1000);

  // Extraterrestrial solar irradiance (I0)
  // Isc is the solar constant, approximately 1367 W/m².
  // https://www.researchgate.net/publication/334139351_Modeling_and_Calculation_of_the_Global_Solar_Irradiance_on_Slopes
  // https://www.ijsdr.org/papers/IJSDR2007039.pdf
  // Коэффициент 0.033 возникает из-за того, что расстояние от Земли до Солнца изменяется на примерно 3.3% в течение года.
  // Значение 0.033 получено эмпирически и примерно соответствует орбитальным параметрам Земли.
  // Оно учитывает максимальное изменение солнечного излучения в зависимости от расстояния Земли до Солнца.
  public extraterrestrialSolarIrradiance = (d: number) =>
    1367 * (1 + 0.033 * cos((360 * d) / 365));

  // Solar Elevation Angle (alpha)
  // https://www.pveducation.org/pvcdrom/properties-of-sunlight/elevation-angle
  public solarElevationAngle = (lat: number, delta: number, HRA: number) =>
    radiansToDegrees(
      Math.asin(sin(lat) * sin(delta) + cos(lat) * cos(delta) * cos(HRA))
    );

  // The angle of the sun (solar declination) delta
  // https://susdesign.com/popups/sunangle/declination.php
  // https://www.pveducation.org/pvcdrom/properties-of-sunlight/declination-angle
  // Solar Engineering of Thermal Processes, Photovoltaics and Wind (p. 32)
  public solarDeclination = (d: number) =>
    radiansToDegrees(Math.asin(sin(-23.45) * cos((360 * (d + 10)) / 365)));

  // Zenith angle (teta)
  // https://www.pveducation.org/pvcdrom/properties-of-sunlight/elevation-angle
  public zenithAngle = (alpha: number) => 90 - alpha;
}
export const solarAngleService = new SolarAngleService();
