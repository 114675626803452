import { useRef, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import { Object3D, MathUtils } from "three";

type SolarPanelProps = {
  url: string;
  rotationX: number;
  tilt: number;
  azimuth: number;
};

export function SolarPanelModel({
  url,
  rotationX,
  tilt,
  azimuth,
}: SolarPanelProps) {
  const { scene } = useGLTF(url);
  const groupRef = useRef<Object3D | null>(null);
  const partRef = useRef<Object3D | null>(null);

  useEffect(() => {
    if (groupRef.current) {
      // const box = new THREE.Box3().setFromObject(groupRef.current);
      // const center = box.getCenter(new THREE.Vector3());
      // const size = box.getSize(new THREE.Vector3());
      groupRef.current.rotation.y = MathUtils.degToRad(0); // -145
      groupRef.current.position.set(0, 0, 0);
    }

    const panelPart = scene.getObjectByName("SolarSurface");
    if (panelPart) {
      partRef.current = panelPart;
    }
  }, [scene]);

  useEffect(() => {
    partRef.current?.rotation.set(MathUtils.degToRad(-rotationX), 0, 0);
  }, [rotationX]);

  useEffect(() => {
    partRef.current?.rotation.set(MathUtils.degToRad(-tilt), 0, 0);
  }, [tilt]);

  useEffect(() => {
    if (groupRef.current) {
      groupRef.current.rotation.y = MathUtils.degToRad(180 + azimuth);
    }
  }, [azimuth]);

  return <primitive object={scene} ref={groupRef} />;
}
