import styled from "styled-components";
import { CSSProperties, PropsWithChildren } from "react";
import { DataResult } from "../../interfaces/SolarPanelPerformanceInterfaces";
import { exportTableToCSV } from "../../helpers/csvHelpder";

type SolarPerformanceTableProps = {
  tableHeaders: string[];
  results: DataResult[];
  showPerformance: boolean;
};

const Th = ({ children }: PropsWithChildren) => (
  <th style={styles.th}>{children}</th>
);

const Td = ({ children }: PropsWithChildren) => (
  <td style={styles.td}>{children}</td>
);

export function SolarPerformanceTable({
  tableHeaders,
  results,
  showPerformance,
}: SolarPerformanceTableProps) {
  if (!results.length)
    return <>Press "Calculate" button to view table with results!</>;

  const exportToCSV = () => {
    const headers = tableHeaders.join(",");
    const rows = results.map((result) =>
      [
        result.num,
        result.tilt.toFixed(2),
        result.azimuth.toFixed(2),
        showPerformance ? result.panelPerformance.toFixed(2) : "",
      ].join(",")
    );

    exportTableToCSV(headers, rows);
  };

  return (
    <div style={styles.container}>
      <StyledButton style={styles.exportButton} onClick={exportToCSV}>
        <i style={styles.icon}></i>
      </StyledButton>
      <table style={styles.table}>
        <thead>
          <tr>
            {tableHeaders.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {results.map((result, index) => (
            <tr key={index}>
              <Td>{result.num}</Td>
              <Td>{result.tilt.toFixed(2)}</Td>
              <Td>{result.azimuth.toFixed(2)}</Td>
              {showPerformance && <Td>{result.panelPerformance.toFixed(2)}</Td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const StyledButton = styled.button({
  position: "absolute",
  top: "5px",
  right: "10px",
  padding: "8px 12px",
  backgroundColor: "#ffcd2e",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  fontSize: "0.9rem",
  opacity: "0.2",
  transition: "opacity 0.2s ease-in-out",
  "&:hover": {
    opacity: "1",
  },
});

const styles: { [key: string]: CSSProperties } = {
  container: {
    position: "relative",
    overflowX: "auto",
    width: "100%",
  },
  icon: {
    display: "block",
    minWidth: "16px",
    minHeight: "16px",
    backgroundImage:
      'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAB2AAAAdgFOeyYIAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAALRJREFUOI3t0T2KQkEQBODPnwWP8M5gLLomvkT2BB5yFzY2MBXUG3gAIyMTRdAFDbZFg5kxMLWgYOjuqq6ZoYwffD+ZKWITzKL5ivvbIG3Qwwp1YraOXq9kOMAZe4zcv3EUtTP6z1JNHkwOwZt4khJ0UWVMLsGUuAqtHaaFJLnNU+za6CQSwC+2cZ4n+lVorXDCZ2Ioh2EkWzYwxgx/WEejhA//d2/h61YcY4mj+8PleMQiNK4f6DRwqxe41gAAAABJRU5ErkJggg==")',
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    minWidth: "280px",
  },
  th: {
    padding: "10px",
    border: "1px solid #ddd",
    backgroundColor: "#f2f2f2",
  },
  td: {
    padding: "10px",
    border: "1px solid #ddd",
  },
};
